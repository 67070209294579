import { Stack } from '@christiankaindl/lyts'
import { type CSSProperties, forwardRef } from 'react'
import * as styles from './Placeholder.css'

interface PlaceholderProps {
  type?: 'text' | 'card' | 'image' | 'absolute'
  aspectRatio?: string
  height?: CSSProperties['height']
  width?: CSSProperties['width']
  style?: CSSProperties
  numLines?: number
}
const Placeholder = forwardRef<HTMLDivElement, PlaceholderProps>(function Placeholder ({ type = 'text', aspectRatio, style = {}, numLines = 1, width, height }, ref) {
  const widths = Array(numLines).fill('').map(() => 7 + Math.round(Math.random() * 2))

  if (type === 'image') {
    return (
      <div className={styles.root} style={{ ...style, width, height, aspectRatio }} ref={ref} />
    )
  } else if (type === 'text') {
    return (
      <Stack gap={0.5}>
        {widths.map((width, index) => {
          return (
            <div className={styles.root} key={index} style={{ height: '1.5em', width: `${width}em` }} />
          )
        })}
      </Stack>
    )
  }

  return null
})

export default Placeholder
