'use client'

import { type CSSProperties, type ReactNode, forwardRef, useRef, useState } from 'react'
import { Desktop } from '../MediaQuery/MediaQuery'
import { motion, useMotionValueEvent, useScroll } from 'framer-motion'
import { theme } from '@/styles/theme.css'
import Button from '../Button/Button'
import { ChevronLeftIcon, ChevronRightIcon } from 'lucide-react'
import { Row } from '@christiankaindl/lyts'
import * as styles from './Reel.css'

const Reel = forwardRef<HTMLDivElement, { children: ReactNode, style?: CSSProperties, innerStyle?: CSSProperties }>(function Reel ({ children, style = {}, innerStyle = {}, ...props }, ref2) {
  const ref = useRef<HTMLDivElement>(null)
  const scroll = useScroll({ container: ref })
  const [arrows, setArrows] = useState<'both' | 'left' | 'right' | 'none'>('right')

  useMotionValueEvent(scroll.scrollXProgress, 'change', (value) => {
    if (value === 0) {
      setArrows('right')
    } else if (value === 1) {
      setArrows('left')
    } else {
      setArrows('both')
    }
  })

  function nextItem () {
    ref.current?.scrollBy({ left: 300, behavior: 'smooth' })
  }
  function prevItem () {
    ref.current?.scrollBy({ left: -300, behavior: 'smooth' })
  }

  return (
    <div
      ref={ref2}
      style={{
        marginLeft: '-1.5em',
        marginRight: '-1.5em',
        backgroundColor: theme.colors.backgrounds.bgA1,
        borderRadius: 24,
        position: 'relative',
        ...style,
      }}
      {...props}
    >
      <Desktop>
        <motion.div style={{ position: 'absolute', left: 9, zIndex: 10, top: '50%', y: '-50%' }} animate={{ scale: arrows === 'both' || arrows === 'left' ? 1 : 0 }}>
          <Button onClick={() => { prevItem() }} circular square size='large' style={{ backdropFilter: 'blur(10px)' }}>
            <ChevronLeftIcon size={20} />
          </Button>
        </motion.div>
        <motion.div style={{ position: 'absolute', right: 9, zIndex: 10, top: '50%', y: '-50%' }} animate={{ scale: arrows === 'both' || arrows === 'right' ? 1 : 0 }}>
          <Button onClick={() => { nextItem() }} circular square size='large' style={{ backdropFilter: 'blur(10px)' }}>
            <ChevronRightIcon size={20} />
          </Button>
        </motion.div>
      </Desktop>
      <Row
        className={styles.root}
        style={{
          // @ts-expect-error CSS custom var
          '--reel-mask-right': arrows === 'both' || arrows === 'right' ? undefined : 0,
          '--reel-mask-left': arrows === 'both' || arrows === 'left' ? undefined : 0,
          ...innerStyle,
        }}
        gap={1.5}
        yAlign='start'
        ref={ref}
      >
        {children}
      </Row>
    </div>
  )
})

export default Reel
