import { forwardRef } from 'react'
import Text, { type TextProps } from '../Text/Text'
import * as styles from './Heading.css'

interface HeadingProps extends TextProps {
  h: 1 | 2 | 3 | 4 | 5 | 6
}

const Heading = forwardRef<HTMLHeadingElement, HeadingProps>(function Heading ({
  children,
  h,
  className = '',
  ...props
}, ref) {
  return (
    <Text
      {...props}
      as={`h${h}`}
      ref={ref}
      className={`${styles[`h${h}`]} ${className}`}
    >
      {children}
    </Text>
  )
})

export default Heading
