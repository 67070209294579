'use client'

import { useInView } from 'framer-motion'
import { useRef, type ReactNode } from 'react'
import { RoughNotation } from 'react-rough-notation'

export function HighlightOnVisible ({ children, delay = 0 }: { children: ReactNode, delay?: number }) {
  const ref = useRef(null)
  const inView = useInView(ref, { once: true, amount: 'all', margin: '100px' })
  console.log('inView', inView)
  return (
    <span ref={ref}>
      <RoughNotation show={inView} type='highlight' animationDelay={delay + 500} multiline>
        {children}
      </RoughNotation>
    </span>
  )
}
