import Link from 'next/link'
import Text from '@/components/Text/Text'
import NotionText from './Text'
import Heading from '../Heading/Heading'
import { type CSSProperties } from 'react'
import { type BlockObjectResponse } from '@notionhq/client/build/src/api-endpoints'
import { env } from '@/env'
import { Row } from '@christiankaindl/lyts'

const renderNestedList = (block) => {
  const { type } = block
  const value = block[type]
  if (!value) return null

  const isNumberedList = value.children[0].type === 'numbered_list_item'

  if (isNumberedList) {
    return (
      <ol>
        {value.children.map((block) => <Block key={block.id} {...block} />)}
      </ol>
    )
  }
  return (
    <ul style={{ paddingLeft: '0px' }}>
      {value.children.map((block) => <Block key={block.id} {...block} />)}
    </ul>
  )
}

export default function Block (params: BlockObjectResponse) {
  const { type, id } = params
  const value = params[type]

  switch (type) {
    case 'paragraph':
      return (
        <Text size='1.1em' weight={300}>
          <NotionText content={value.rich_text} />
        </Text>
      )
    case 'heading_1':
      return (
        <Heading h={1}>
          <NotionText content={value.rich_text} />
        </Heading>
      )
    case 'heading_2':
      return (
        <Heading h={2} style={{ marginTop: '1.5em' }}>
          <NotionText content={value.rich_text} />
        </Heading>
      )
    case 'heading_3':
      return (
        <Heading h={3} style={{ marginTop: '0.75em' }}>
          <NotionText content={value.rich_text} />
        </Heading>
      )
    case 'bulleted_list_item':
    case 'numbered_list_item':
      return (
        <ul style={{ margin: 0 }}>
          <li style={{ marginTop: -9 }}>
            <Text size='1.1em' weight={300} style={{ display: 'inline' }}>
              <NotionText content={value.rich_text} />
            </Text>
            {!!value.children && renderNestedList(params)}
          </li>
        </ul>
      )
    case 'to_do':
      return (
        <div>
          <label htmlFor={id}>
            <input type='checkbox' id={id} defaultChecked={value.checked} />{' '}
            <NotionText content={value.rich_text} />
          </label>
        </div>
      )
    case 'toggle':
      return (
        <details>
          <summary>
            <NotionText content={value.rich_text} />
          </summary>
          {value.children?.map((block) => (
            <Block key={block.id} {...block} />
          ))}
        </details>
      )
    case 'child_page':
      return <p>{value.title}</p>
    case 'image': {
      const caption = value.caption ? value.caption[0]?.plain_text : ''
      return (
        <NotionImage
          alt={caption}
          blockId={id}
          width={1200}
          height={630}
        />
      )
    }
    case 'divider':
      return <hr key={id} />
    case 'quote':
      return <blockquote key={id}>{value.rich_text[0].plain_text}</blockquote>
    case 'code':
      return (
        <pre>
          <code>
            {value.rich_text[0].plain_text}
          </code>
        </pre>
      )
    case 'file': {
      const srcFile =
        value.type === 'external' ? value.external.url : value.file.url
      const splitSourceArray = srcFile.split('/')
      const lastElementInArray = splitSourceArray[splitSourceArray.length - 1]
      const captionFile = value.caption ? value.caption[0]?.plain_text : ''
      return (
        <figure>
          <div>
            📎{' '}
            <Link href={srcFile} passHref>
              {lastElementInArray.split('?')[0]}
            </Link>
          </div>
          {captionFile && <figcaption>{captionFile}</figcaption>}
        </figure>
      )
    }
    case 'bookmark': {
      const href = value.url
      return (
        <a href={href} target='_brank'>
          {href}
        </a>
      )
    }
    case 'video': {
      return (
        <video controls src={value.file.url} />
      )
    }
    case 'callout': {
      return (
        <Row yAlign='start' gap={0.75} style={{ borderRadius: 18, padding: 21, backgroundImage: 'linear-gradient(147.2deg, rgba(255, 222, 160, 0.1) 28.57%, rgba(254, 180, 38, 0.08) 93.28%)', border: '1px solid rgba(247, 231, 201, 0.6)' }}>
          <span style={{ fontSize: '1.1em' }}>
            {value.icon.emoji}
          </span>
          <Text color='primary' size='1.1em' style={{ whiteSpace: 'break-spaces' }}>
            <NotionText content={value.rich_text} />
          </Text>
        </Row>
      )
    }
    default:
      return (
        <>
          ❌ Unsupported block {
            type === 'unsupported' ? 'unsupported by Notion API' : type
          }
        </>
      )
  }
}

export const NotionImage: React.FC<{
  alt: string
  blockId: string
  style?: CSSProperties
  width?: number
  height?: number
}> = ({ alt, blockId, style = {}, width = 1200, height = 630 }) => {
  return (
    <div>
      <img
        src={`https://${env.NEXT_PUBLIC_BUCKET_NAME}.s3.eu-central-1.amazonaws.com/data/notion/images/${blockId}`}
        alt={alt}
        width={width}
        height={height}
        style={{ borderRadius: 12, border: '1px solid rgb(0 0 0 / 0.14)', boxShadow: 'rgb(0 0 0 / 0.1) 0px 3px 21px -7px', ...style }}
        loading='lazy'
      />
    </div>
  )
}
