import { getDecisionUrl, getUrl } from '@/lib/utils'
import Button from '../Button/Button'
import ClausePreview from '../ClauseHoverPreview'
import type { DecisionRef, LawRef } from '@gesetzefinden-at/core'

export default function DecisionButton ({ id, openInNewTab, ...ref }: (LawRef | DecisionRef) & { openInNewTab?: boolean, id: string }) {
  return (
    <ClausePreview id={id}>
      <Button
        size='small'
        variant='accent'
        key={id}
        href={(ref.documentType === 'decision' || ref.documentType === 'rechtssatz')
          ? getDecisionUrl(ref)
          : (ref.documentType === 'law' || ref.documentType === 'clause') ? getUrl(ref.lawType, { norm: ref.abbreviation, enumeration: ref.enumeration !== '§ 0' ? ref.enumeration : undefined }) : ''
        }
        target={openInNewTab ? '_blank' : undefined}
      >
        {ref.citation}
      </Button>
    </ClausePreview>
  )
}
