'use client'

import Button from '@/components/Button/Button'
import Spinner from '@/components/Spinner/Spinner'
import { ArrowRightIcon } from 'lucide-react'
import { useFormStatus } from 'react-dom'

export default function SubmitButton () {
  const { pending } = useFormStatus()
  return (
    <Button variant="brand" circular size="large" type='submit' disabled={pending} style={{ marginLeft: -2 }}>
      Anfrage abschicken { pending ? <Spinner /> : <ArrowRightIcon size={20} /> }
    </Button>
  )
}
