import NotionText from '@/components/Notion/Text'
import Text from '@/components/Text/Text'
import { type PageObjectResponse } from '@notionhq/client/build/src/api-endpoints'

type Props = {
  property: keyof PageObjectResponse['properties']
  type: PageObjectResponse['properties'][string]['type']
} & PageObjectResponse
export default function PropertyText ({ property, type, ...content }: Props) {
  return (
    <Text color='inherit'>
      <NotionText
        // content={content.properties[property].type === type && content.properties[property]}
        content={content.properties[property][type]}
      />
    </Text>
  )
}

export function extractPropertyText (content: PageObjectResponse['properties'][string]) {
  if (content.type === 'rich_text') {
    return content.rich_text.map(({ plain_text: plainText }) => plainText).join('')
  } else if (content.type === 'title') {
    return content.title.map(({ plain_text: plainText }) => plainText).join('')
  }
  return ''
}
