'use client'

import Text from '@/components/Text/Text'
import { theme, card, surfaces } from '@/styles/theme.css'
import { Stack, Row, Box } from '@christiankaindl/lyts'
import { MailOpenIcon } from 'lucide-react'
import Image from 'next/image'
import { useEffect, useRef, useState } from 'react'
import emailPreview from '@/public/assets/amiga-monitoring-email-preview.png'
import * as styles from './page.css'

export default function ScrollingPreview (props) {
  const previewRef = useRef<HTMLDivElement | null>(null)

  // const { scrollYProgress } = useScroll({
  //   target: previewRef,
  //   offset: ['start start', 'end end'],
  // })

  // const v = useTransform(scrollYProgress, [0, 1], ['0%', '-60%'], { clamp: true })

  return (
    <Stack gap={0.75} {...props} style={{ ...props.style, height: 10, flexGrow: 1, borderRadius: 18 }} className={`${styles.previewColumn} ${card}`} ref={previewRef}>
      <Row gap={0.75} style={{ position: 'relative', zIndex: 1 }} className={surfaces.surface1}>
        <Box xAlign='center' yAlign='center' style={{ height: 36, width: 36, borderRadius: 99, backgroundColor: theme.colors.backgrounds.bgA2 }}>
          <MailOpenIcon size={20} />
        </Box>
        <Stack gap={0}>
          <Text size='small' maxLines={1}>AmigaAI Monitoring Report vom  15.04.2024 – 21.04.2023</Text>
          <Text size='small' color='tertiary'>monitoring@gesetzefinden.at</Text>
        </Stack>
      </Row>
      <Image priority src={emailPreview} alt='' style={{ borderRadius: 9, height: '100%', objectFit: 'cover', overflow: 'hidden', objectPosition: 'top left', marginLeft: -6, marginBottom: -6 }} />
    </Stack>
  )
}

/**
 * detects when a (CSS) sticky element changes "sticky" state
 * @param {object} ref optional react ref. if not provided, a new one will be used instead.
 * @param {object} observerSettings Observer's settings object
*/
export function useDetectSticky (ref, observerSettings: IntersectionObserverInit = { threshold: [1] }) {
  const [isSticky, setIsSticky] = useState(false)

  // mount
  useEffect(() => {
    const cachedRef = ref.current
    const observer = new IntersectionObserver(
      ([e]) => { console.log(e); setIsSticky(e.isIntersecting && e.intersectionRatio === 1) },
      observerSettings,
    )

    observer.observe(cachedRef)

    // unmount
    return () => {
      observer.unobserve(cachedRef)
    }
  }, [])

  return [isSticky]
}
