import { Clamp, type ClampProps, Stack, type StackProps } from '@christiankaindl/lyts'

export type MakeOptional<Type, Key extends keyof Type> = Omit<Type, Key> & Partial<Pick<Type, Key>>
export default function Section ({ children, clamp = '1150px', gap = 3, style = {}, stackProps = {}, ...props }: MakeOptional<ClampProps, 'clamp'> & { stackProps?: StackProps }): JSX.Element {
  return (
    <Clamp
      {...props}
      clamp={clamp}
      style={{
        minHeight: '60vh',
        padding: '4.5em 1.5em',
        ...style,
      }}
    >
      <Stack gap={gap} {...stackProps}>
        {children}
      </Stack>
    </Clamp>
  )
}
