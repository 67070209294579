import(/* webpackMode: "eager", webpackExports: ["ContactOverlay","SearchWithTypewriter","ReelSection","StatsSection"] */ "/vercel/path0/app/_components.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/amiga/entscheidungsmonitoring/hero-image.png");
;
import(/* webpackMode: "eager", webpackExports: ["HighlightOnVisible"] */ "/vercel/path0/app/amiga/entscheidungsmonitoring/HighlightOnVisible.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/amiga/entscheidungsmonitoring/monitoring-workflow.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/amiga/entscheidungsmonitoring/ScrollingPreview.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/amiga/entscheidungsmonitoring/WaitlistForm.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/Button/Button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/Faq/FaqItem.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/Footer/Footer.css.ts");
;
import(/* webpackMode: "eager", webpackExports: ["Mobile","Desktop"] */ "/vercel/path0/components/MediaQuery/MediaQuery.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/Menu/Menu.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/Menu/MenuItem.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/Menu/MenuSeparator.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/NavBar/Background.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/NavBar/MobileSearchOverlay.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/NavBar/NavBarMenus.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NavBarSearch"] */ "/vercel/path0/components/NavBar/NavBarSearch.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/Page/Page.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/Text/Text.css.ts");
;
import(/* webpackMode: "eager", webpackExports: ["Tooltip","TooltipTrigger","TooltipContent"] */ "/vercel/path0/components/Tooltip/Tooltip.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ClientClerkProvider"] */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/app-router/client/ClerkProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["AuthenticateWithRedirectCallback","ClerkLoaded","ClerkLoading","RedirectToCreateOrganization","RedirectToOrganizationProfile","RedirectToSignIn","RedirectToSignUp","RedirectToUserProfile"] */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/client-boundary/controlComponents.js");
;
import(/* webpackMode: "eager", webpackExports: ["useAuth","useClerk","useEmailLink","useOrganization","useOrganizationList","useReverification","useSession","useSessionList","useSignIn","useSignUp","useUser"] */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/client-boundary/hooks.js");
;
import(/* webpackMode: "eager", webpackExports: ["PromisifiedAuthProvider"] */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/client-boundary/PromisifiedAuthProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["CreateOrganization","GoogleOneTap","OrganizationList","OrganizationProfile","OrganizationSwitcher","SignIn","SignInButton","SignInWithMetamaskButton","SignOutButton","SignUp","SignUpButton","UserButton","UserProfile","Waitlist"] */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/client-boundary/uiComponents.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22components%2FFooter%2FFooter.css.ts.vanilla.css%22%2C%22source%22%3A%22Ll8xcnlvNHFhMCB7CiAgYmFja2dyb3VuZC1jb2xvcjogdmFyKC0tXzFkeWZsZDExbSk7CiAgYm9yZGVyLXRvcDogMXB4IHNvbGlkIHJnYmEoMCwgMCwgMCwgLjA4KTsKICBwYWRkaW5nOiA2ZW0gMS41ZW07Cn0KQG1lZGlhIHByaW50IHsKICAuXzFyeW80cWEwIHsKICAgIGRpc3BsYXk6IG5vbmU7CiAgfQp9%22%7D");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22components%2FText%2FText.css.ts.vanilla.css%22%2C%22source%22%3A%22cCB7CiAgbWF4LXdpZHRoOiA2NWNoOwogIGxpbmUtaGVpZ2h0OiAxLjU3NTsKICBmb250LXNpemU6IC45NWVtOwogIGNvbG9yOiB2YXIoLS1fMWR5ZmxkMTE2KTsKICAtd2Via2l0LWxpbmUtY2xhbXA6IHZhcigtLW1heC1saW5lcyk7CiAgLXdlYmtpdC1ib3gtb3JpZW50OiB2ZXJ0aWNhbDsKICBvdmVyZmxvdzogaGlkZGVuOwogIGRpc3BsYXk6IC13ZWJraXQtYm94OwogIHRleHQtb3ZlcmZsb3c6IGVsbGlwc2lzOwp9Ci5fMWdveXJ1dTAgewogIGh5cGhlbnM6IGF1dG87Cn0%3D%22%7D");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/Heading/Heading.css.ts");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22components%2FHeading%2FHeading.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA82UQW6DMBBF95xilomEUQgBWmfTm1QOHmIrxraMG6AVd69MkxSqdtNFm%2B2fGfv9L82INAaxjUFkMYhdDCKPQRTwFgE0rCed5F5QKPJK7CMAJTUSgfIoPIU0ycs8qLXRnrTyFSkkjzk2QauMMo7CmbkVIc8pH2rF07RYhxrp8HCSnkyvVYo19toYvgxqu%2Bg7mJ4YJ1F7Cmd0XlZMhbo5o6uV6SgIyTnqoHHZWsUGOh8Ousfek88BVEraVrb7aIySWhnpT5vJ9OSlZo1UwxVKG29I0Nc3s90lgnKz%2BSaWLGhisAJ1S4G9eHMj6ByzFA5MMV3h3MO14pCdSGcc%2FxLsNskuySr0Hh1pLaukPlIgSZnbfmYk%2FcmI1GHwv52kSTE5ufFuf8Nb%2FCFvtuTN7p03XfLu7px3AZvfHewYPTXIJYPV7CCWxYPt1xPr4nwsd%2FZjYcdofAfUrD6%2FZgUAAA%3D%3D%22%7D");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/Notion/Text.css.ts");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22components%2FNotion%2FText.css.ts.vanilla.css%22%2C%22source%22%3A%22Ll8xd2pxdHBsMCB7CiAgZm9udC13ZWlnaHQ6IGJvbGQ7Cn0KLl8xd2pxdHBsMSB7CiAgZm9udC1zdHlsZTogaXRhbGljOwp9Ci5fMXdqcXRwbDIgewogIHRleHQtZGVjb3JhdGlvbjogbGluZS10aHJvdWdoOwp9Ci5fMXdqcXRwbDMgewogIHRleHQtZGVjb3JhdGlvbjogdW5kZXJsaW5lOwp9Ci5fMXdqcXRwbDQgewogIGZvbnQtZmFtaWx5OiBtb25vc3BhY2U7Cn0%3D%22%7D");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/Pill/Pill.css.ts");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22components%2FPill%2FPill.css.ts.vanilla.css%22%2C%22source%22%3A%22Ll8xMDhjYmkwMCB7CiAgYmFja2dyb3VuZC1jb2xvcjogdmFyKC0tXzFkeWZsZDExaSk7CiAgYm9yZGVyLXJhZGl1czogOTk5cHg7CiAgcGFkZGluZzogM3B4IDEycHg7CiAgbWluLWhlaWdodDogY2FsYygzcHggKyAyMHB4KTsKICBmb250LXNpemU6IC44ZW07CiAgY29sb3I6IHZhcigtLV8xZHlmbGQxMTcpOwogIGN1cnNvcjogZGVmYXVsdDsKICBkaXNwbGF5OiBpbmxpbmUtZmxleDsKICB3aWR0aDogZml0LWNvbnRlbnQ7Cn0%3D%22%7D");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/Placeholder/Placeholder.css.ts");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22components%2FPlaceholder%2FPlaceholder.css.ts.vanilla.css%22%2C%22source%22%3A%22QGtleWZyYW1lcyBmeW94ZWswIHsKICAwJSB7CiAgICBiYWNrZ3JvdW5kLXBvc2l0aW9uLXg6IDEwMCU7CiAgfQogIDc1JSB7CiAgICBiYWNrZ3JvdW5kLXBvc2l0aW9uOiAtMTAwJTsKICB9CiAgMTAwJSB7CiAgICBiYWNrZ3JvdW5kLXBvc2l0aW9uOiAtMTAwJTsKICB9Cn0KLmZ5b3hlazEgewogIGJvcmRlci1yYWRpdXM6IDlweDsKICBiYWNrZ3JvdW5kLWNvbG9yOiBsaWdodGdyYXk7CiAgYmFja2dyb3VuZDogCmxpbmVhci1ncmFkaWVudCh0byByaWdodCwgcmdiKDAgMCAwIC8gMC4wMikgMCUsIHJnYigwIDAgMCAvIDAuMDgpIDQwJSwgcmdiKDAgMCAwIC8gMC4wOCkgNjAlLCByZ2IoMCAwIDAgLyAwLjAyKSAxMDAlKTsKICBiYWNrZ3JvdW5kLXNpemU6IDIwMCUgMTAwJTsKICBhbmltYXRpb246IGZ5b3hlazAgMS4ycyBlYXNlLWluLW91dCBpbmZpbml0ZTsKfQ%3D%3D%22%7D");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/amiga/entscheidungsmonitoring/page.css.ts");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22app%2Famiga%2Fentscheidungsmonitoring%2Fpage.css.ts.vanilla.css%22%2C%22source%22%3A%22QG1lZGlhIHNjcmVlbiBhbmQgKG1heC13aWR0aDogNzEycHgpIHsKICAuXzFwM3ZmMWowIHsKICAgIGZvbnQtc2l6ZTogMmVtICFpbXBvcnRhbnQ7CiAgICBmb250LWZhbWlseTogdmFyKC0taW50ZXItZm9udCkgIWltcG9ydGFudDsKICB9CiAgLl8xcDN2ZjFqMSB7CiAgICBhbGlnbi1zZWxmOiBzdGFydDsKICAgIHBhZGRpbmctdG9wOiBjYWxjKDJlbSArIDY0cHgpOwogIH0KICAuXzFwM3ZmMWoyIHsKICAgIGxlZnQ6IGNhbGMoLTEyMCUgLSA4cHggKyA1MHZ3KTsKICAgIHBvc2l0aW9uOiByZWxhdGl2ZTsKICAgIGRpc3BsYXk6IGZsZXg7CiAgICB3aWR0aDogMTIwJTsKICAgIGFsaWduLXNlbGY6IHN0YXJ0OwogIH0KICAuXzFwM3ZmMWozIHsKICAgIGdhcDogM2VtICFpbXBvcnRhbnQ7CiAgfQp9CkBtZWRpYSBzY3JlZW4gYW5kIChtaW4td2lkdGg6IDcxMnB4KSB7CiAgLl8xcDN2ZjFqMCB7CiAgICBmb250LXNpemU6IDNlbSAhaW1wb3J0YW50OwogICAgZm9udC1mYW1pbHk6IHZhcigtLWludGVyLWZvbnQpICFpbXBvcnRhbnQ7CiAgfQogIC5fMXAzdmYxajEgewogICAgYWxpZ24tc2VsZjogc3RhcnQ7CiAgICBwYWRkaW5nLXRvcDogY2FsYygyZW0gKyA2NHB4KTsKICB9CiAgLl8xcDN2ZjFqMiB7CiAgICBsZWZ0OiBjYWxjKC0xMjAlIC0gOHB4ICsgNTB2dyk7CiAgICBwb3NpdGlvbjogcmVsYXRpdmU7CiAgICBkaXNwbGF5OiBmbGV4OwogICAgd2lkdGg6IDEyMCU7CiAgICBhbGlnbi1zZWxmOiBzdGFydDsKICB9CiAgLl8xcDN2ZjFqMyB7CiAgICBnYXA6IDZlbSAhaW1wb3J0YW50OwogIH0KfQ%3D%3D%22%7D");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/legacy/image.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["RoughNotation"] */ "/vercel/path0/node_modules/react-rough-notation/dist/module.es.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/amiga-bg.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/amiga-monitoring-logo.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/brutkasten-logo.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/cerha-hempel-logo.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/data-gv-at-logo.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/fwp-logo.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/herbst-kinsky-logo.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/hfp-logo.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/jusprofi-logo.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/ksv1870.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/lawfinder-logo.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/legal-tech-logo.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/philipp-miller-logo.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/rechtsanwalt-logo.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/smp-logo.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/trendingTopics-logo.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/weisenheimer-logo.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/wko-logo.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/logo-horizontal.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/logo-vertical.png");
