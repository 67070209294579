import Link from 'next/link'
import * as styles from './Text.css'

export default function NotionText ({ content }) {
  if (!content) {
    return null
  }
  if (typeof content === 'string') {
    return content
  }
  return content.map((value) => {
    if (value.href?.startsWith('https://drive.google.com')) {
      const id = value.href.split('/').at(-2)
      if (!id) {
        return null
      }
      // See https://stackoverflow.com/a/20675890/8464260
      const url = `https://drive.google.com/uc?id=${id as string}`
      return (
        <img
          key={value.href}
          src={url}
          alt=''
          style={{ borderRadius: 12, border: '1px solid rgb(0 0 0 / 0.15)' }}
        />
      )
    }
    const {
      annotations: { bold, code, color, italic, strikethrough, underline },
      text,
    } = value
    return (
      <span
        key={value.id}
        className={[
          bold ? styles.bold : '',
          code ? styles.code : '',
          italic ? styles.italic : '',
          strikethrough ? styles.strikethrough : '',
          underline ? styles.underline : '',
        ].join(' ')}
        style={color !== 'default' ? { color } : {}}
      >
        {text.link ? <Link href={text.link.url}>{text.content}</Link> : text.content}
      </span>
    )
  })
}
